






































































































































































































































import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { VForm } from '@/types/v-form';
import { ActionInterface, PrintActionInterface } from '@/types/breadcrumb';
import Layout from '@/layouts/LayoutDefault.vue';

import FirmStructureTree from '@/components/utils/FirmStructureTree.vue';

import CheFolder from '@modules/chemical-risk/entities/CheFolder';
import ProductFolder from '@modules/chemical-risk/entities/ProductFolder';
import Pictogram from '@modules/chemical-risk/entities/Pictogram';
import HazardStatement from '@modules/chemical-risk/entities/HazardStatement';
import Substance from '@modules/chemical-risk/entities/Substance';
import * as Rules from '@/utils/CustomFormRules';

const cheModule = namespace('cheRisk');

@Component({
  components: {
    Layout,
    FirmStructureTree,
  },
})
export default class IdentificationEditView extends Vue {
  @cheModule.State('currentProductFolder')
  currentProductFolder!: ProductFolder;

  @cheModule.Action('patchProductFolder')
  patchProductFolder!: any;

  @cheModule.Action('getSubstances')
  getSubstances!: (label: string) => any;

  @cheModule.Getter('getActiveCheFolder')
  activeCheFolder!: CheFolder | null;

  @cheModule.Getter('getHazardStatementByVersion')
  getHazardStatementByVersion!: (version: number) => HazardStatement[];

  @cheModule.Getter('getPictogramByVersion')
  getPictogramByVersion!: (version: number) => Pictogram[];

  productFolder: ProductFolder = new ProductFolder();
  checkAll: boolean = false;
  loading: boolean = false;
  isFormDisabled: boolean = true;
  substances: any = [];
  transfereElement: any = '';
  personalNumber: any = '';

  @Watch('productFolder.productSheet.substances')
  onSubstancesChange() {
    if (this.productFolder.productSheet.substances.length > 0) {
      this.productFolder.productSheet.hasNoSubstance = false;
    }
  }

  @Watch('productFolder.productSheet.hasNoSubstance')
  onChange() {
    if (this.productFolder.productSheet.substances.length > 0) {
      this.productFolder.productSheet.hasNoSubstance = false;
    }
  }

  get printAction(): PrintActionInterface | null {
    const { productSheet } = this.productFolder;
    if (productSheet && productSheet.fileUrl) {
      return {
        href: `${productSheet.fileUrl}`,
        tooltip: (productSheet.isPublic) ? 'Télécharger le PDF de la FDS du pot commun' : 'Télécharger le PDF de la FDS',
      };
    }

    return null;
  }

  get actions(): ActionInterface[] {
    return this.isGranted('chemi-risks-identification', 2) && this.productFolder.isEditable ? [
      {
        label: 'Enregistrer et revenir',
        handler: () => this.save(),
      },
      {
        label: 'Annuler',
        handler: () => this.handleRedirect('chemi-risks-identification'),
      },
    ] : [];
  }

  get dataHazardStatements(): any {
    const hazards = this.getHazardStatementByVersion(this.productFolder.productSheet.isOldVersion ? 1 : 2);
    const transfer: any = [];
    hazards.forEach((h) => {
      transfer.push({ key: h.id, label: `[${h.reference}] ${h.label}`, value: h.reference });
    });
    return transfer;
  }

  get dataPictograms(): Array<Pictogram> {
    return this.getPictogramByVersion(this.productFolder.productSheet.isOldVersion ? 1 : 2);
  }

  get rules(): any {
    const rules: any = {
      productSheet: {
        nonHazardStatement: [
          {
            required: true,
            message: this.$t('form.required_field'),
            trigger: 'blur',
            boolValue: this.productFolder.productSheet.nonHazardStatement || (this.productFolder.productSheet.pictograms.length > 0 || this.productFolder.productSheet.caution !== 0 || this.productFolder.productSheet.hazardStatements.length > 0),
            validator: Rules.checkBoolean,
          },
        ],
        substances: [],
      },
    };
    this.productFolder.productSheet.substances.forEach((s) => {
      const substance = {
        CASNumber: [
          {
            required: true,
            message: this.$t('form.required_field'),
            trigger: 'blur',
          },
        ],
        quantity: [
          {
            required: true,
            message: this.$t('form.required_field'),
            trigger: 'blur',
          },
        ],
      };
      rules.productSheet.substances.push(substance);
    });
    return rules;
  }

  casNumber(cas: string) {
    return cas.split(';')[0];
  }

  productName() {
    return `${this.productFolder.productSheet.label} - ${this.productFolder.productSheet.vendor} ${this.productFolder.productSheet.year}`;
  }

  handleCheckedPictogramsChange(value: any) {
    const checkedCount = value.length;
    this.checkAll = checkedCount === this.dataPictograms.length;
    this.productFolder.productSheet.nonHazardStatement = checkedCount === 0;
  }

  transferTrigger(index?: number) {
    setTimeout(() => {
      if (typeof index !== 'undefined') {
        (((this.$refs.transfer as Array<any>)[index] as Vue).$refs.leftPanel as any).query = '';
        (((this.$refs.transfer as Array<any>)[index] as Vue).$refs.rightPanel as any).query = '';
        this.transfereElement = (((this.$refs.transfer as Array<any>)[index] as Vue).$el as HTMLDivElement);
      } else {
        ((this.$refs.mention as Vue).$refs.leftPanel as any).query = '';
        ((this.$refs.mention as Vue).$refs.rightPanel as any).query = '';
        this.transfereElement = ((this.$refs.mention as Vue).$el as HTMLDivElement);
      }
      this.transfereElement.querySelector('.el-transfer__button:not(.is-disabled)').click();
    }, 50);
  }

  handleNonChange(value: any) {
    if (value === true) {
      this.productFolder.productSheet.hazardStatements = [];
      this.productFolder.productSheet.pictograms = [];
      this.productFolder.productSheet.caution = 0;
      this.checkAll = false;
    }
  }

  handleNoPictograms() {
    if (this.productFolder.productSheet.pictograms.length < 1) {
      // this.productFolder.productSheet.nonHazardStatement = true;
    }
  }

  filterMethod(query: any, item: any) {
    return `[${item.reference}] ${item.label}`.toLowerCase().indexOf(query.toLowerCase()) > -1;
  }

  addSubstance() {
    const subst = new Substance();
    subst.hazardStatements = [];
    this.productFolder.productSheet.substances.push(subst);
  }

  removeSubstance(index: number) {
    this.productFolder.productSheet.substances.splice(index, 1);
  }

  getSubstancesList(query: string) {
    this.personalNumber = '';
    if (query !== '') {
      this.personalNumber = query;
      this.loading = true;
      setTimeout(async () => {
        this.loading = false;
        this.substances = await this.getSubstances(query);
        if (this.substances.length === 0) {
          const virtualMutuSubstance = {
            annexeViIndex: '',
            quantity: '',
            name: 'Non référencé',
            casNumbers: '',
            ecNumbers: '',
            personalNumber: query,
          };
          this.substances.push(virtualMutuSubstance);
        }
      }, 200);
    } else {
      this.substances = [];
    }
  }

  useSubstance(substanceMutu: any, index: number) {
    const substanceE1 = this.productFolder.productSheet.substances[index];

    if (substanceMutu.personalNumber) {
      substanceE1.personalNumber = substanceMutu.personalNumber;
    } else if (substanceMutu.casNumbers && this.casNumber(substanceMutu.casNumbers).includes(this.personalNumber)) {
      substanceE1.personalNumber = this.casNumber(substanceMutu.casNumbers);
    } else if (substanceMutu.ecNumbers && substanceMutu.ecNumbers.includes(this.personalNumber)) {
      substanceE1.personalNumber = substanceMutu.ecNumbers;
    } else if (substanceMutu.annexeViIndex && substanceMutu.annexeViIndex.includes(this.personalNumber)) {
      substanceE1.personalNumber = substanceMutu.annexeViIndex;
    } else {
      substanceE1.personalNumber = substanceMutu.personalNumber;
    }
    substanceE1.referenceId = substanceMutu.annexeViIndex;
    substanceE1.quantity = '';
    substanceE1.hazardStatements = [];
    substanceE1.label = substanceMutu.name;
    substanceE1.CASNumber = this.casNumber(substanceMutu.casNumbers);
    substanceE1.ECNumber = substanceMutu.ecNumbers;
    if (substanceMutu.classificationHazardStatementCodes) {
      const hazardStatementsMutu = substanceMutu.classificationHazardStatementCodes.split(', ');
      hazardStatementsMutu.forEach((h: any) => {
        if (this.dataHazardStatements.find((data: any) => data.value === h)) {
          substanceE1.hazardStatements.push(this.dataHazardStatements.find((data: any) => data.value === h).key);
        }
      });
    }
    const refs: any = this.$refs.closedSubstances;
    refs.forEach((e: any) => {
      e.blur();
    });
  }

  save(): void {
    const form = this.$refs.form as VForm;

    let validForm = false;
    let errorFields = {};
    form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (validForm) {
      if (this.activeCheFolder && this.activeCheFolder.status !== 0 && this.activeCheFolder.status !== null) {
        this.openConfirm();
      } else {
        this.submitForm();
      }
    } else {
      this.$message({
        type: 'info',
        message: 'Donnée(s) manquante(s)',
      });
    }
  }

  openConfirm() {
    this.$confirm('Attention la modification de ce produit entrainera la remise à zéro de l\'analyse du dossier en cours', 'Attention', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Annuler',
      type: 'warning',
    }).then(() => {
      this.submitForm();
    });
  }

  submitForm(): void {
    this.patchProductFolder(this.productFolder).then((response: any) => {
      this.$message({
        type: 'success',
        message: 'Données mises à jour',
      });
      this.handleRedirect('chemi-risks-identification');
    });
  }

  mounted(): void {
    this.productFolder = _.cloneDeep(this.currentProductFolder);
    if (this.productFolder.isEditable && this.isGranted('chemi-risks-identification', 2) && this.activeCheFolder && [0].includes(this.activeCheFolder.status)) {
      this.isFormDisabled = false;
    }
  }
}
